export type LocationType = {
  name: string;
  fullName: string;
  id: string;
  costLastWeek?: number;
  costLastMonth?: number;
  costCurrentWeek?: number;
  costCurrentMonth?: number;
  costCurrentDay?: number;
  costYesterday?: number;
  costTotal?: number;
};

export const locationToJson = (location: LocationType) => {
  return {
    name: location.name,
    fullName: location.fullName,
  };
};

export const noDataLocation: LocationType = {
  name: "No Data",
  fullName: "No Data",
  id: "",
};
