import { Dispatch, FC, SetStateAction, useState } from "react";
import { RoleType } from "../../models/Roles";
import { Button, Divider, Form, Input, Popconfirm, Space, message } from "antd";
import { deleteRole, updateRole } from "../../utils/firebase";

import styles from "./EditWorker.module.scss";
import Loading from "../Loading";

type Props = {
  roles: RoleType[];
  setRoles: Dispatch<SetStateAction<RoleType[]>>;
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const EditRoles: FC<Props> = ({ roles, setRoles }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {roles.map((r) => {
        return (
          <div key={r.id}>
            {isLoading && <Loading />}
            <Form
              className={styles.form}
              {...formItemLayout}
              form={form}
              size="large"
              name={r.id}
              style={{ maxWidth: 800 }}
              scrollToFirstError
            >
              <Divider />
              <Form.Item
                initialValue={r.basicPay}
                name={r.name + "current"}
                label={"Basic pay for " + r.name}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item name={r.name} label="New Basic Pay">
                <Input type="number" />
              </Form.Item>

              <Form.Item {...tailFormItemLayout} className={styles.buttonRow}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      !form.getFieldValue(r.name) ||
                      form.getFieldValue(r.name).trim().length === 0
                    )
                      return;
                    setIsLoading(true);

                    r.basicPay = form.getFieldValue(r.name);

                    updateRole({ basicPay: r.basicPay }, r.id).then(() => {
                      setRoles((w) => {
                        return w.map((wo) => (wo.id === r.id ? r : wo));
                      });
                      form.setFieldValue(
                        r.name + "current",
                        form.getFieldValue(r.name)
                      );
                      setIsLoading(false);
                      message.success("Updated");
                    });
                  }}
                >
                  Change {r.name}
                </Button>

                <Popconfirm
                  placement="topRight"
                  title={"Delete Role"}
                  description={"Are you sure you want to delete " + r.name}
                  okText="Yes"
                  cancelText="Cancel"
                  onConfirm={async () => {
                    setIsLoading(true);
                    await deleteRole(r).then(() => {
                      setRoles((ro) => {
                        return [...ro].filter((rol) => rol.id !== r.id);
                      });
                      message.success("Deleted");
                    });
                    setIsLoading(false);
                  }}
                >
                  <a className={styles.delete}>Delete</a>
                </Popconfirm>
              </Form.Item>
            </Form>
          </div>
        );
      })}
    </>
  );
};

export default EditRoles;
