import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FC, useState } from "react";
import App from "./App";
import Login from "./Components/Login";

const AuthController: FC = () => {
  const [user, setUser] = useState<any>();
  const auth = getAuth();
  const [pw, setPw] = useState<string>("");

  onAuthStateChanged(auth, (user) => {
    if (user) {
     
        setUser(user);
      } 
    else {
      setUser(undefined);
    }
  });

  return user ? <App user={user} password={pw} /> : <Login setPw={setPw} />;
};

export default AuthController;
