export function formatDateToUS(milliseconds: number) {
  const date = new Date(milliseconds);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
  const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single-digit days
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function getTimeInAMPM(milliseconds: number) {
  const date = new Date(milliseconds);
  const hours = date.getHours() % 12; // Get 12-hour format (0-11)
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Pad minutes with leading zero if needed
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  // Return formatted time (e.g., 10:23 AM)
  return `${hours}:${minutes} ${ampm}`;
}

function toWeekDay(day: number) {
  if (day === 0) return 6;
  return day - 1;
}
function checkLastYear(year: number, month: number) {
  if (month === 0) return year - 1;
  return year;
}

function checkNextYear(year: number, month: number) {
  if (month === 11) return year + 1;
  return year;
}

const oneMinute = 1000 * 60;
const oneHour = oneMinute * 60;
const oneDay = oneHour * 24;

const date: Date = new Date();
const monthDate: Date = new Date(date.getFullYear(), date.getMonth(), 1);
const lastMonthDate: Date = new Date(
  checkLastYear(date.getFullYear(), date.getMonth()),
  date.getMonth() - 1,
  1
);
const nextMonthDate: Date = new Date(
  checkNextYear(date.getFullYear(), date.getMonth()),
  date.getMonth() + 1,
  1
);
const startOfMonth = monthDate.getTime();
const startOfWeek =
  date.getTime() -
  toWeekDay(date.getDay()) * oneDay -
  date.getHours() * oneHour -
  date.getMinutes() * oneMinute -
  date.getSeconds() * 1000;
const startOfDay =
  date.getTime() -
  date.getHours() * oneHour -
  date.getMinutes() * oneMinute -
  date.getSeconds() * 1000;
const startOfYesterday = startOfDay - oneDay;
const startOfLastWeek = startOfWeek - oneDay * 7;
const startOfLastMonth = lastMonthDate.getTime();
const startOfNextMonth = nextMonthDate.getTime();

export const isToday = (millis: number) => {
  return millis >= startOfDay && millis < startOfDay + oneDay;
};

export const isThisWeek = (millis: number) => {
  return millis >= startOfWeek && millis < startOfWeek + oneDay * 7;
};

export const isThisMonth = (millis: number) => {
  return millis >= startOfMonth && millis < startOfNextMonth;
};

export const isYesterday = (millis: number) => {
  return millis >= startOfYesterday && millis < startOfDay;
};

export const isLastWeek = (millis: number) => {
  return millis >= startOfLastWeek && millis < startOfWeek;
};

export const isLastMonth = (millis: number) => {
  return millis >= startOfLastMonth && millis < startOfMonth;
};
