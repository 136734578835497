import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import type { CascaderProps } from "antd";
import styles from "./EditWorker.module.scss";
import { format } from "date-fns";

import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  message,
  DatePicker,
  Form,
  notification,
  Input,
  InputNumber,
  Row,
  Select,
  Divider,
} from "antd";
import { WorkerType } from "../../models/Worker";
import { RoleType } from "../../models/Roles";
import { updateWorker } from "../../utils/firebase";
import Loading from "../Loading";
import { NotificationInstance } from "antd/es/notification/interface";
import { AddonType } from "../../models/Addon";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

type Props = {
  addons: AddonType[];
  roles: RoleType[];
  worker: WorkerType;
  finishEdit: () => void;
  setWorkers: Dispatch<SetStateAction<WorkerType[]>>;
};

const EditWorker: React.FC<Props> = ({
  worker,
  finishEdit,
  roles,
  setWorkers,
  addons,
}) => {
  const [form] = Form.useForm();
  const [workerX, setWorkerX] = useState(worker);
  const [isLoading, setIsLoading] = useState(false);

  /* worker.rolePay.forEach((r) => {
    roles.forEach((ro) => {
      if (r.roleId === ro.id) {
        r.name = ro.name;
      }
    });
  });

  worker.addonPay.forEach((r) => {
    addons.forEach((ro) => {
      if (r.addonId === ro.id) {
        r.name = ro.name;
      }
    });
  }); */

  const onFinish = (values: any) => {};

  return (
    <>
      {isLoading && <Loading />}
      <header className={styles.editHeader}>
        <h1>Edit {workerX.name}</h1>
        <button onClick={() => finishEdit()}> Back</button>
      </header>
      <Form
        className={styles.form}
        {...formItemLayout}
        form={form}
        size="large"
        name="editWorkerForm"
        onFinish={onFinish}
        style={{ maxWidth: 800 }}
        scrollToFirstError
      >
        {/* Name */}
        <Form.Item
          initialValue={workerX.name}
          className={styles.input}
          name="currentName"
          label="Current Name"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item className={styles.input} name="name" label="New Name">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (
                !form.getFieldValue("name") ||
                form.getFieldValue("name").trim().length === 0
              )
                return;
              setIsLoading(true);

              updateWorker(
                { name: form.getFieldValue("name").trim() },
                workerX.id
              ).then(() => {
                workerX.name = form.getFieldValue("name").trim();
                setWorkers((w) => {
                  return w.map((wo) => (wo.id === workerX.id ? workerX : wo));
                });
                form.setFieldValue(
                  "currentName",
                  form.getFieldValue("name").trim()
                );
                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Name
          </Button>
        </Form.Item>

        {/* Nickname */}
        <Divider />
        <Form.Item
          initialValue={workerX.nick}
          className={styles.input}
          name="currentNick"
          label="Current Nicknam"
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item name="nick" label="New Nickname">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            type="primary"
            onClick={() => {
              if (
                !form.getFieldValue("nick") ||
                form.getFieldValue("nick").trim().length === 0
              )
                return;
              setIsLoading(true);

              updateWorker(
                { nick: form.getFieldValue("nick").trim() },
                workerX.id
              ).then(() => {
                workerX.nick = form.getFieldValue("nick").trim();
                setWorkers((w) => {
                  return w.map((wo) => (wo.id === workerX.id ? workerX : wo));
                });
                form.setFieldValue(
                  "currentNick",
                  form.getFieldValue("nick").trim()
                );
                setIsLoading(false);
                message.success("Updated");
              });
            }}
          >
            Change Nickname
          </Button>
        </Form.Item>

        {/* Email */}
        <Divider />

        <Form.Item
          initialValue={workerX.email}
          className={styles.input}
          name="email"
          label="Email"
        >
          <Input disabled={true} />
        </Form.Item>

        {/* Password */}
        <Form.Item
          initialValue={workerX.password}
          className={styles.input}
          name="password"
          label="Password"
        >
          <Input disabled={true} />
        </Form.Item>
        {/*   <Divider />
        {workerX.rolePay.length > 0 && <h1>Roles Pay Changed</h1>}

        {workerX.rolePay.map((r) => {
          return (
            <>
              <Divider />
              <Form.Item
                initialValue={r.amount}
                className={styles.input}
                name={r.name + "current"}
                label={"Current pay for " + r.name}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item name={r.name} label="New Pay">
                <Input type="number" />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      !form.getFieldValue(r.name) ||
                      form.getFieldValue(r.name).trim().length === 0
                    )
                      return;
                    setIsLoading(true);

                    // r.amount = form.getFieldValue(r.name)

                    workerX.rolePay.forEach((e) => {
                      if (e.roleId === r.roleId) {
                        e.amount = form.getFieldValue(r.name);
                      }
                    });

                    updateWorker({ rolePay: workerX.rolePay }, workerX.id).then(
                      () => {
                        setWorkers((w) => {
                          return w.map((wo) =>
                            wo.id === workerX.id ? workerX : wo
                          );
                        });
                        form.setFieldValue(
                          r.name + "current",
                          form.getFieldValue(r.name)
                        );
                        setIsLoading(false);
                        message.success("Updated");
                      }
                    );
                  }}
                >
                  Change {r.name}
                </Button>
              </Form.Item>
            </>
          );
        })}

        <h1>Role Pay Basic</h1>
        {roles.map((r) => {
          const roleIds = workerX.rolePay.map((e) => e.roleId);
          if (roleIds.includes(r.id)) return;
          return (
            <>
              <Divider />
              <Form.Item
                initialValue={r.basicPay}
                className={styles.input}
                name={r.name + "current"}
                label={"Current pay for " + r.name}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item name={r.name} label="New Pay">
                <Input type="number" />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      !form.getFieldValue(r.name) ||
                      form.getFieldValue(r.name).trim().length === 0
                    )
                      return;
                    setIsLoading(true);
                    if (workerX.rolePay.find((e) => e.roleId === r.id)) {
                      workerX.rolePay.forEach((e) => {
                        if (e.roleId === r.id) {
                          e.amount = form.getFieldValue(r.name);
                        }
                      });
                    } else {
                      workerX.rolePay.push({
                        roleId: r.id,
                        amount: form.getFieldValue(r.name),
                      });
                    }
                    updateWorker({ rolePay: workerX.rolePay }, workerX.id).then(
                      () => {
                        setWorkers((w) => {
                          return w.map((wo) =>
                            wo.id === workerX.id ? workerX : wo
                          );
                        });
                        form.setFieldValue(
                          r.name + "current",
                          form.getFieldValue(r.name)
                        );
                        setIsLoading(false);
                        message.success("Updated");
                      }
                    );
                  }}
                >
                  Change {r.name}
                </Button>
              </Form.Item>
            </>
          );
        })}
 */}
        {/* Addons */}
        {/* 
        {workerX.addonPay.length > 0 && <h1>Addons Pay Changed</h1>}

        {workerX.addonPay.map((r) => {
          return (
            <>
              <Divider />
              <Form.Item
                initialValue={r.amount}
                className={styles.input}
                name={r.name + "current"}
                label={"Current pay for " + r.name}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item name={r.name} label="New Pay">
                <Input type="number" />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      !form.getFieldValue(r.name) ||
                      form.getFieldValue(r.name).trim().length === 0
                    )
                      return;
                    setIsLoading(true);

                    // r.amount = form.getFieldValue(r.name)

                    workerX.addonPay.forEach((e) => {
                      if (e.addonId === r.addonId) {
                        e.amount = form.getFieldValue(r.name);
                      }
                    });

                    updateWorker(
                      { addonPay: workerX.addonPay },
                      workerX.id
                    ).then(() => {
                      setWorkers((w) => {
                        return w.map((wo) =>
                          wo.id === workerX.id ? workerX : wo
                        );
                      });
                      form.setFieldValue(
                        r.name + "current",
                        form.getFieldValue(r.name)
                      );
                      setIsLoading(false);
                      message.success("Updated");
                    });
                  }}
                >
                  Change {r.name}
                </Button>
              </Form.Item>
            </>
          );
        })}

        <h1>Addons Pay Basic</h1>

        {addons.map((r) => {
          const addonId = workerX.addonPay.map((e) => e.addonId);
          if (addonId.includes(r.id)) return;
          return (
            <>
              <Divider />
              <Form.Item
                initialValue={r.basicPay}
                className={styles.input}
                name={r.name + "current"}
                label={"Current pay for " + r.name}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item name={r.name} label="New Pay">
                <Input type="number" />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      !form.getFieldValue(r.name) ||
                      form.getFieldValue(r.name).trim().length === 0
                    )
                      return;
                    setIsLoading(true);
                    if (workerX.addonPay.find((e) => e.addonId === r.id)) {
                      workerX.addonPay.forEach((e) => {
                        if (e.addonId === r.id) {
                          e.amount = form.getFieldValue(r.name);
                        }
                      });
                    } else {
                      workerX.addonPay.push({
                        addonId: r.id,
                        amount: form.getFieldValue(r.name),
                      });
                    }
                    updateWorker(
                      { addonPay: workerX.addonPay },
                      workerX.id
                    ).then(() => {
                      setWorkers((w) => {
                        return w.map((wo) =>
                          wo.id === workerX.id ? workerX : wo
                        );
                      });
                      form.setFieldValue(
                        r.name + "current",
                        form.getFieldValue(r.name)
                      );
                      setIsLoading(false);
                      message.success("Updated");
                    });
                  }}
                >
                  Change {r.name}
                </Button>
              </Form.Item> 
            </>
          );
        })} */}
      </Form>
    </>
  );
};

export default EditWorker;
