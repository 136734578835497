import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";



const auth = getAuth();


export const signIn = async (email: string, password: string) => {
  let user;
  await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return undefined;
    });
  return user;
};

export const signAccountOut = async () => {
  await signOut(auth)
    .then(() => {
      
    })
    .catch((error) => {});
};



export const createUser = async (email: string, password: string) => {
  let userX;
  await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      userX = user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      return undefined;
    });

  return userX;
};
