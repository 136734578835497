import { Dispatch, FC, SetStateAction, useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { addAddon, addLocation, addWorker } from "../../utils/firebase";
import { workerToJson } from "../../models/Worker";
import { WorkerType } from "../../models/Worker";
import { LocationType } from "../../models/Location";
import { AddonType } from "../../models/Addon";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
type Props = {
  setAddons: Dispatch<SetStateAction<AddonType[]>>;
  setSuccess: () => void;
  setLoading: () => void;
};

const AddonForm: React.FC<Props> = ({ setAddons, setSuccess, setLoading }) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const addon = values as AddonType;
    setLoading();
    const id = await addAddon(addon);
    addon.id = id ? id : "";
    setAddons((w: AddonType[]) => {
      return [...w, addon];
    });
    if (id) {
      setSuccess();
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      size="large"
      name="workerForm"
      onFinish={onFinish}
      style={{ maxWidth: 800 }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            type: "string",
            message: "The input is not valid!",
          },
          {
            required: true,
            message: "Please add the name!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="basicPay" label="Basic Pay" required>
        <Input type="number" required />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddonForm;
