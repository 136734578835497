import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import type { CascaderProps } from "antd";
import styles from "./EditLocation.module.scss";
import { format } from "date-fns";

import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  message,
  DatePicker,
  Form,
  notification,
  Input,
  InputNumber,
  Row,
  Select,
  Divider,
} from "antd";
import { WorkerType } from "../../models/Worker";
import { RoleType } from "../../models/Roles";
import { updateLocation, updateWorker } from "../../utils/firebase";
import Loading from "../Loading";
import { NotificationInstance } from "antd/es/notification/interface";
import { LocationType } from "../../models/Location";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

//semething is wrong here! Edit name fails for sure. Continue here

type Props = {
  location: LocationType;
  finishEdit: () => void;
  setLocations: Dispatch<SetStateAction<LocationType[]>>;
};

const EditLocation: React.FC<Props> = ({
  location,
  finishEdit,

  setLocations,
}) => {
  const [form] = Form.useForm();
  const [locationX, setlocationX] = useState(location);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values: any) => {};

  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {isLoading && <Loading />}
      <header className={styles.editHeader}>
        <h1>Edit {locationX.name}</h1>
        <button onClick={() => finishEdit()}> Back</button>
      </header>
      <Form
        className={styles.form}
        {...formItemLayout}
        form={form}
        size="large"
        name="editWorkerForm"
        onFinish={onFinish}
        initialValues={
          {
            // residence: ["zhejiang", "hangzhou", "xihu"],
            // prefix: "86",
          }
        }
        style={{ maxWidth: 600 }}
        scrollToFirstError
      >
        <Form.Item
          initialValue={locationX.name}
          className={styles.input}
          name="currentName"
          label="Current Short Name"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item className={styles.input} name="name" label="New Short Name">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (
                !form.getFieldValue("name") ||
                form.getFieldValue("name").trim().length === 0
              )
                return;
              setIsLoading(true);

              updateLocation(
                { name: form.getFieldValue("name").trim() },
                locationX.id
              ).then(() => {
                locationX.name = form.getFieldValue("name").trim();
                setLocations((w: LocationType[]) => {
                  return w.map((wo) =>
                    wo.id === locationX.id ? locationX : wo
                  );
                });
                form.setFieldValue(
                  "currentName",
                  form.getFieldValue("name").trim()
                );
                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Short Name
          </Button>
        </Form.Item>

        {/* Full Name */}
        <Divider />
        <Form.Item
          initialValue={locationX.fullName}
          className={styles.input}
          name="currentFullName"
          label="Current Full Name"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          className={styles.input}
          name="fullName"
          label="New Full Name"
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (
                !form.getFieldValue("fullName") ||
                form.getFieldValue("fullName").trim().length === 0
              )
                return;
              setIsLoading(true);

              updateLocation(
                { fullName: form.getFieldValue("fullName").trim() },
                locationX.id
              ).then(() => {
                locationX.fullName = form.getFieldValue("fullName").trim();
                setLocations((w: LocationType[]) => {
                  return w.map((wo) =>
                    wo.id === locationX.id ? locationX : wo
                  );
                });
                form.setFieldValue(
                  "currentFullName",
                  form.getFieldValue("fullName").trim()
                );
                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Name
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditLocation;
