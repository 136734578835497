import { LocationType } from "../models/Location";
import { WorkerType } from "../models/Worker";
import { WorkUnitType } from "../models/WorkUnit";
import {
  isThisWeek,
  isToday,
  isLastMonth,
  isLastWeek,
  isThisMonth,
  isYesterday,
} from "./time";

export const addCostToLocations = (
  locations: LocationType[],
  workUnits: WorkUnitType[]
) => {
  workUnits.forEach((wu) => {
    locations.forEach((l) => {
      if (!l.costTotal) l.costTotal = 0;
      if (!l.costCurrentDay) l.costCurrentDay = 0;
      if (!l.costCurrentMonth) l.costCurrentMonth = 0;
      if (!l.costCurrentWeek) l.costCurrentWeek = 0;
      if (!l.costLastMonth) l.costLastMonth = 0;
      if (!l.costLastWeek) l.costLastWeek = 0;
      if (!l.costYesterday) l.costYesterday = 0;

      if (l.id === wu.location.id) {
        l.costTotal = wu.total + l.costTotal;
        if (isToday(wu.start)) l.costCurrentDay += wu.total;
        if (isYesterday(wu.start)) l.costYesterday += wu.total;
        if (isThisWeek(wu.start)) l.costCurrentWeek += wu.total;
        if (isThisMonth(wu.start)) l.costCurrentMonth += wu.total;
        if (isLastMonth(wu.start)) l.costLastMonth += wu.total;
        if (isLastWeek(wu.start)) l.costLastWeek += wu.total;
      }
    });
  });
};

export const addCostToWorkers = (
  workers: WorkerType[],
  workUnits: WorkUnitType[]
) => {
  workUnits.forEach((wu) => {
    workers.forEach((l) => {
      if (!l.costTotal) l.costTotal = 0;
      if (!l.costCurrentDay) l.costCurrentDay = 0;
      if (!l.costCurrentMonth) l.costCurrentMonth = 0;
      if (!l.costCurrentWeek) l.costCurrentWeek = 0;
      if (!l.costLastMonth) l.costLastMonth = 0;
      if (!l.costLastWeek) l.costLastWeek = 0;
      if (!l.costYesterday) l.costYesterday = 0;

      if (l.id === wu.worker.id) {
        l.costTotal += wu.total;
        if (isToday(wu.start)) l.costCurrentDay += wu.total;
        if (isYesterday(wu.start)) l.costYesterday += wu.total;
        if (isThisWeek(wu.start)) l.costCurrentWeek += wu.total;
        if (isThisMonth(wu.start)) l.costCurrentMonth += wu.total;
        if (isLastMonth(wu.start)) l.costLastMonth += wu.total;
        if (isLastWeek(wu.start)) l.costLastWeek += wu.total;
      }
    });
  });
};
