import { FC } from "react";
import logo from "../assets/greif.png";

import styles from "./Loading.module.scss";

const Loading: FC = () => {
  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" className={styles.img}></img>
    </div>
  );
};

export default Loading;
