// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {
  getDocs,
  collection,
  getFirestore,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore/lite";
import {
  LocationType,
  locationToJson,
  noDataLocation,
} from "../models/Location";
import { WorkerType, noDataWorker, workerToJson } from "../models/Worker";
import { WorkUnitType, workUnitToJson } from "../models/WorkUnit";
import { AddonType, addonToJson } from "../models/Addon";
import { RoleType, roleToJson } from "../models/Roles";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTMeuwx56OFSJrXoCkimrFzDQisjBGI_c",
  authDomain: "hulu---work-cordination.firebaseapp.com",
  projectId: "hulu---work-cordination",
  storageBucket: "hulu---work-cordination.appspot.com",
  messagingSenderId: "270207138271",
  appId: "1:270207138271:web:a79950883f438edabe6c6a",
};

const workerColleciton = "workers";
const locationColleciton = "locations";
const addonColleciton = "addons";
const roleColleciton = "roles";
const workUnitColleciton = "workunits";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export async function fetchLocations() {
  const col = collection(db, locationColleciton);
  const snap = await getDocs(col);
  const list: LocationType[] = snap.docs.map((doc) => {
    const loc = doc.data() as LocationType;
    return { ...loc, id: doc.id };
  }) as LocationType[];

  list.sort((a, b) => {
    if (a.name < b.name) {
      return -1; // a comes before b
    } else if (a.name > b.name) {
      return 1; // b comes before a
    } else {
      return 0; // a and b are equal
    }
  });
  return list;
}

export async function fetchWorkers(roles: RoleType[]) {
  const col = collection(db, workerColleciton);
  const snap = await getDocs(col);
  const list: WorkerType[] = snap.docs.map((doc) => {


    return { ...doc.data(), id: doc.id  };
  }) as WorkerType[];


  list.sort((a, b) => {
    if (a.name < b.name) {
      return -1; // a comes before b
    } else if (a.name > b.name) {
      return 1; // b comes before a
    } else {
      return 0; // a and b are equal
    }
  });
  return list;
}

export async function fetchAddons() {
  const col = collection(db, addonColleciton);
  const snap = await getDocs(col);
  const list: AddonType[] = snap.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  }) as AddonType[];
  list.forEach((a) => {
    if (!a.isCustom) {
      a.isCustom = false;
    }
  });

  list.sort((a, b) => {
    if (a.name < b.name) {
      return -1; // a comes before b
    } else if (a.name > b.name) {
      return 1; // b comes before a
    } else {
      return 0; // a and b are equal
    }
  });

  return list;
}

export async function fetchRoles() {
  const col = collection(db, roleColleciton);
  const snap = await getDocs(col);
  const list: RoleType[] = snap.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  }) as RoleType[];

  list.sort((a, b) => {
    if (a.name < b.name) {
      return -1; // a comes before b
    } else if (a.name > b.name) {
      return 1; // b comes before a
    } else {
      return 0; // a and b are equal
    }
  });

  return list;
}

export async function fetchWorkUnits(
  workers: WorkerType[],
  locations: LocationType[],
  roles: RoleType[]
) {
  const col = collection(db, workUnitColleciton);
  const snap = await getDocs(col);
  const list: WorkUnitType[] = snap.docs.map((doc) => {
    const wX = doc.data()["workerId"]
    let w;
    if(!!wX) {
       w = workers.find((w) => w.id === wX);
    } else {
      w = workers.find((w) => w.id === doc.data()["userId"].trim())
    }


    const l = locations.find((l) => l.id === doc.data()["locationId"].trim());
    const r = doc.data()["roles"] as RoleType[];
    return {
      ...doc.data(),
      id: doc.id,
      worker: w ? w : noDataWorker,
      location: l ? l : noDataLocation,
      roles: r ? r : [],
    };
  }) as WorkUnitType[];

  list.sort((a, b) => {
    if (a.start > b.start) {
      return -1; // a comes before b
    } else if (a.start < b.start) {
      return 1; // b comes before a
    } else {
      return 0; // a and b are equal
    }
  });

  return list;
}

// ################ ADD #######################

export async function addWorker(worker: WorkerType) {
  try {
    const workerCollectionRef = collection(db, workerColleciton);
    const newWorkerRef = await addDoc(
      workerCollectionRef,
      workerToJson(worker)
    );

    return newWorkerRef.id;
  } catch (error) {
    console.error("Error adding worker:", error);
  }
}

export async function addLocation(location: LocationType) {
  try {
    const locationCollectionRef = collection(db, locationColleciton);
    const ref = await addDoc(locationCollectionRef, locationToJson(location));

    return ref.id;
  } catch (error) {
    console.error("Error adding location:", error);
  }
}

export async function addAddon(addon: AddonType) {
  try {
    const addonCollectionRef = collection(db, addonColleciton);
    const newAddonRef = await addDoc(addonCollectionRef, addonToJson(addon));
    return newAddonRef.id;
  } catch (error) {
    console.error("Error adding addon:", error);
  }
}

export async function addRole(role: RoleType) {
  try {
    const roleCollectionRef = collection(db, roleColleciton);
    const newRoleRef = await addDoc(roleCollectionRef, roleToJson(role));
    return newRoleRef.id;
  } catch (error) {
    console.error("Error adding role:", error);
  }
}

export async function addWorkUnit(unit: WorkUnitType) {
  console.log("addd unit ", unit)
  try {
    const workUnitCollectionRef = collection(db, workUnitColleciton);
    const newWorkUnitRef = await addDoc(
      workUnitCollectionRef,
      workUnitToJson(unit)
    );

    return newWorkUnitRef.id;
  } catch (error) {
    console.error("Error adding workunit:", error);
  }
}

// Delete

export async function deleteLocation(location: LocationType) {
  try {
    const locationRef = doc(collection(db, locationColleciton), location.id);

    await deleteDoc(locationRef);
  } catch (error) {
    console.error("Error deleting location:", error);
  }
}

export async function deleteWorker(worker: WorkerType) {
  try {
    const workerRef = doc(collection(db, workerColleciton), worker.id);

    await deleteDoc(workerRef);
  } catch (error) {
    console.error("Error deleting worker:", error);
  }
}

export async function deleteWorkUnit(unit: WorkUnitType) {
  try {
    const workUnitRef = doc(collection(db, workUnitColleciton), unit.id);

    await deleteDoc(workUnitRef);
  } catch (error) {
    console.error("Error deleting workunit:", error);
  }
}

export async function deleteAddon(unit: AddonType) {
  try {
    const ref = doc(collection(db, addonColleciton), unit.id);

    await deleteDoc(ref);
  } catch (error) {
    console.error("Error deleting addon:", error);
  }
}

export async function deleteRole(val: RoleType) {
  try {
    const ref = doc(collection(db, roleColleciton), val.id);

    await deleteDoc(ref);
  } catch (error) {
    console.error("Error deleting role:", error);
  }
}

// Update

export async function updateLocation(attributeJson: any, id: string) {
  try {
    const ref = doc(collection(db, locationColleciton), id);
    await updateDoc(ref, attributeJson);
  } catch (error) {
    console.error("Error adding worker:", error);
  }
}

export async function updateWorker(attributeJson: any, workerId: string) {
  try {
    const workerRef = doc(collection(db, workerColleciton), workerId);
    await updateDoc(workerRef, attributeJson);
  } catch (error) {
    console.error("Error adding worker:", error);
  }
}

export async function updateWorkUnit(attributeJson: any, unitId: string) {
  try {
    const workUnitRef = doc(collection(db, workUnitColleciton), unitId);
    await updateDoc(workUnitRef, attributeJson);
  } catch (error) {
    console.error("Error adding workUnit:", error);
  }
}

export async function updateAndCalcTotalOfWorkUnit(unit: WorkUnitType) {
  try {
    const ref = doc(collection(db, workUnitColleciton), unit.id);
    let currentTotal = 0;
    unit.addons.forEach((element) => {
      currentTotal += element.basicPay;
    });
    unit.roles.forEach((element) => {
      currentTotal += element.basicPay;
    });
    unit.total = currentTotal;
    await updateDoc(ref, { total: currentTotal });
  } catch (error) {
    console.error("Error updating total:", error);
  }
}

export async function updateRole(attributeJson: any, id: string) {
  try {
    const ref = doc(collection(db, roleColleciton), id);
    await updateDoc(ref, attributeJson);
  } catch (error) {
    console.error("Error updating role:", error);
  }
}

export async function updateAddon(attributeJson: any, id: string) {
  try {
    const ref = doc(collection(db, addonColleciton), id);
    await updateDoc(ref, attributeJson);
  } catch (error) {
    console.error("Error updating addon:", error);
  }
}
