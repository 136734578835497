import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

import { Button, Input, Popconfirm, Space, Table, Tag } from "antd";
import type { GetRef, TableColumnType, TableProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import styles from "./WorkUnits.module.scss";
import { FilterDropdownProps } from "antd/es/table/interface";
import { WorkUnitType } from "../models/WorkUnit";
import { formatDateToUS, getTimeInAMPM } from "../utils/time";
import EditWorkUnit from "./forms/EditWorkUnit";
import { RoleType } from "../models/Roles";
import { LocationType } from "../models/Location";
import { WorkerType } from "../models/Worker";
import { AddonType } from "../models/Addon";
import { deleteWorkUnit } from "../utils/firebase";

interface DataType {
  index: number;
  workUnit: WorkUnitType;
  worker: string;
  location: string;
  roles: string;
  expandable: boolean;
}

type DataIndex = keyof DataType;
type InputRef = GetRef<typeof Input>;

interface ExpandedDataType {
  workUnit: WorkUnitType;
}

export type Props = {
  addons: AddonType[];
  workUnits: WorkUnitType[];
  workers: WorkerType[];
  roles: RoleType[];
  locations: LocationType[];
  setWorkUnits: Dispatch<SetStateAction<WorkUnitType[]>>;
};

const WorkUnits: FC<Props> = ({
  workUnits,
  addons,
  workers,
  locations,
  roles,
  setWorkUnits,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const [editWorkUnit, setEditWorkUnit] = useState<WorkUnitType>();

  const createExpandWorkUnits = (record: any) => {
    const columns = [
      {
        title: "Note",
        dataIndex: "workUnit",
        key: "roles",
        render: (x: any, val: any) => {
          return val.note;
        },
      },
    ];
    const data: ExpandedDataType[] = [record.workUnit];
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  function finishEdit() {
    setEditWorkUnit(undefined);
  }

  if (editWorkUnit) {
    return (
      <EditWorkUnit
        addons={addons}
        workUnit={editWorkUnit}
        workers={workers}
        finishEdit={finishEdit}
        roles={roles}
        setWorkUnits={setWorkUnits}
        locations={locations}
      />
    );
  }

  const updatedData: DataType[] = workUnits.map((unit, index) => {
    return {
      key: unit.id,
      index: index,
      workUnit: unit,
      worker: unit.worker.name,
      location: unit.location.name,
      roles: unit.roles.map((r) => r.name).join(", "),
      expandable: unit.note.length !== 0 ? true : false,
    };
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Start",
      dataIndex: "workUnit",
      key: "workUnit",
      render: (unit: WorkUnitType) => {
        return formatDateToUS(unit.start) + " - " + getTimeInAMPM(unit.start);
      },

      sorter: (a, b) => b.workUnit.start - a.workUnit.start,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "End",
      dataIndex: "workUnit",
      key: "workUnit2",
      render: (unit: WorkUnitType) => {
        return unit.end === undefined || unit.end === null
          ? ""
          : formatDateToUS(unit.end) + " - " + getTimeInAMPM(unit.end);
      },
      sorter: (a, b) => {
        // Check for undefined values first
        if (typeof a.workUnit.end === "undefined") {
          return 1; // Move undefined values to the top (positive value)
        } else if (typeof b.workUnit.end === "undefined") {
          return -1; // Keep defined values below (negative value)
        }

        // If both are defined, sort based on the actual end values
        return b.workUnit.end - a.workUnit.end;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Worker",
      dataIndex: "worker",
      key: "worker",
      render: (unit: WorkUnitType) => {
        return unit.worker.name;
      },
      ...getColumnSearchProps("worker"),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (unit: WorkUnitType) => {
        return unit.location.name;
      },
      ...getColumnSearchProps("location"),
    },

    {
      title: "Roles",
      dataIndex: "workUnit",
      key: "workUnit",
      render: (unit: WorkUnitType) => {
        return unit.roles.length === 0
          ? ""
          : unit.roles.map((a) => a.name + ": $" + a.basicPay).join(", ");
      },
    },
    {
      title: "Addons",
      dataIndex: "workUnit",
      key: "workUnit",
      render: (unit: WorkUnitType) => {
        return unit.addons.length === 0
          ? ""
          : unit.addons.map((a) => a.name + ": $" + a.basicPay).join(", ");
      },
    },

    {
      title: "Total",
      dataIndex: "workUnit",
      key: "workUnit",
      render: (unit: WorkUnitType) => {
        return "$" + unit.total;
      },
    },

    {
      title: "Action",
      key: "workUnit",
      render: (val, record) => (
        <Space size="middle">
          <a onClick={() => setEditWorkUnit(val.workUnit)}>Edit</a>

          <Popconfirm
            placement="topRight"
            title={"Delete WorkUnit"}
            description={
              "Are you sure you want to delete this workunit from " +
              val.workUnit.worker.name
            }
            okText="Yes"
            cancelText="Cancel"
            onConfirm={async () => {
              await deleteWorkUnit(val.workUnit);
              setWorkUnits((locs: WorkUnitType[]) => {
                return [
                  ...locs.filter((l: WorkUnitType) => l.id !== val.workUnit.id),
                ];
              });
            }}
          >
            <a className={styles.delete}>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const isRowExpandable = (record: any) => {
    return record.expandable === true;
  };

  return (
    <div>
      <Table
        pagination={{ hideOnSinglePage: true }}
        columns={columns}
        dataSource={updatedData}
        rowClassName={({ index }) => (index % 2 === 1 ? "even-row" : "")}
        className={styles.table}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: createExpandWorkUnits,
          rowExpandable: isRowExpandable,
          defaultExpandAllRows: false,
        }}
      />
    </div>
  );
};

export default WorkUnits;
