export type WorkerType = {
  name: string;
  nick: string;
  email: string;
  id: string;
  uid: string;
  password: string;
  /*   rolePay: { roleId: string; amount: number; name?: string }[];
  addonPay: { addonId: string; amount: number; name?: string }[]; */

  costLastWeek?: number;
  costLastMonth?: number;
  costCurrentWeek?: number;
  costCurrentMonth?: number;
  costCurrentDay?: number;
  costYesterday?: number;
  costTotal?: number;
};

export const workerToJson = (worker: WorkerType) => {
  return {
    name: worker.name,
    nick: worker.nick,
    password: worker.password ? worker.password : "",
    email: worker.email,
  };
};

export const noDataWorker: WorkerType = {
  name: "No Data",
  nick: "No Data",
  email: "",
  id: "",
  password: "",
  uid: "",
  /*  rolePay: [],
  addonPay: [], */
};
