import { AddonType, addonToJson } from "./Addon";
import { LocationType, locationToJson } from "./Location";
import { RoleType, roleToJson } from "./Roles";
import { WorkerType, workerToJson } from "./Worker";

export type WorkUnitType = {
  id: string;
  roles: RoleType[];
  start: number;
  end?: number;
  worker: WorkerType;
  location: LocationType;
  addons: AddonType[];
  note: string;
  total: number;
};

export const workUnitToJson = (unit: WorkUnitType) => {
  const json = {
    roles: unit.roles.map((r) => roleToJson(r)),
    start: unit.start,
    end: unit.end,
    workerId: unit.worker.id,
    locationId: unit.location.id,
    addons: unit.addons.map((a) => addonToJson(a)),
    note: unit.note,
    total: unit.total,
  };
  return json;
};

export class WorkUnitUtils {
  hasStarted = (workUnit: WorkUnitType) => {
    return Date.now() > workUnit.start;
  };

  hasFinished = (workUnit: WorkUnitType) => {
    if (workUnit.end === undefined) return false;
    return Date.now() > workUnit.end;
  };

  isCurrent = (workUnit: WorkUnitType) => {
    return this.hasStarted(workUnit) && !this.hasFinished(workUnit);
  };
}
