import { Dispatch, FC, SetStateAction, useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { addLocation, addWorker } from "../../utils/firebase";
import { workerToJson } from "../../models/Worker";
import { WorkerType } from "../../models/Worker";
import { LocationType } from "../../models/Location";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

type Props = {
  setLocations: Dispatch<SetStateAction<LocationType[]>>;
  setSuccess: () => void;
  setLoading: () => void;
};

const LocationForm: React.FC<Props> = ({
  setLocations,
  setSuccess,
  setLoading,
}) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const location = values as LocationType;
    setLoading();
    const id = await addLocation(location);
    location.id = id ? id : "";
    setLocations((w: LocationType[]) => {
      return [...w, location];
    });
    if (id) {
      setSuccess();
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      size="large"
      name="workerForm"
      onFinish={onFinish}
      initialValues={{
        // residence: ["zhejiang", "hangzhou", "xihu"],
        prefix: "86",
      }}
      style={{ maxWidth: 800 }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Short Name"
        rules={[
          {
            type: "string",
            message: "The input is not valid!",
          },
          {
            required: true,
            message: "Please add the name!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Please add the name!" }]}
        name="fullName"
        label="New Full Name"
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LocationForm;
