import { Dispatch, FC, SetStateAction, useState } from "react";
import { Button, Form, Input } from "antd";
import { addWorker, updateWorker } from "../../utils/firebase";
import { workerToJson } from "../../models/Worker";
import { WorkerType } from "../../models/Worker";
import { RoleType } from "../../models/Roles";
import { createUser, signAccountOut, signIn } from "../../utils/auth";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function createPw() {
  const digits = "0123456789";
  let randomString = "";
  for (let i = 0; i < 6; i++) {
    randomString += digits[Math.floor(Math.random() * digits.length)];
  }
  return randomString;
}

type Props = {
  setWorkers: Dispatch<SetStateAction<WorkerType[]>>;
  setSuccess: () => void;
  setLoading: () => void;
  roles: RoleType[];
  userX: {password: string, email: string}
};

const WorkerForm: React.FC<Props> = ({
  setWorkers,
  setSuccess,
  setLoading,
  roles,
  userX
}) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const worker = values as WorkerType;
    const pw = createPw();
    worker.password = pw;
    worker.email = worker.name.replace(" ", "").toLowerCase() + "@hawaii.com";
  
    setLoading();
    const id = await addWorker(worker);
    worker.id = id ? id : "";
    if (worker.id) {
      const user = await createUser(worker.email, pw);
      if (user) {
        signAccountOut()
        signIn(userX.email, userX.password)
        worker.uid = user["uid"];

        updateWorker({ uid: user["uid"] }, worker.id);
      }
    }
    setWorkers((w: WorkerType[]) => {
      return [...w, worker];
    });

    if (!!id) {
      setSuccess();
    }

  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      size="large"
      name="workerForm"
      onFinish={onFinish}
      style={{ maxWidth: 800 }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            type: "string",
            message: "The input is not valid!",
          },
          {
            required: true,
            message: "Please add the name!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="nick"
        label="Nickname"
        rules={[
          {
            type: "string",
            message: "The input is not valid!",
          },
          {
            required: true,
            message: "Please add the name!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WorkerForm;
