import { FC, useState } from "react";
import styles from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/greif.png";
import { signAccountOut } from "../utils/auth";

type Props = {
  refresh: () => void;
};

const Header: FC<Props> = ({ refresh }) => {
  const [selectedPage, setSelectedPage] = useState(window.location.pathname);
  const navigate = useNavigate();
  return (
    <header>
      <img src={logo} alt="logo" className={styles.logo}></img>
      <h2 className={styles.refresh} onClick={refresh}>
        refresh
      </h2>
      <div className={styles.headerButtons}>
        <Link to="/locations">
          <button
            onClick={() => setSelectedPage("/locations")}
            className={
              selectedPage === "/locations"
                ? styles.selected
                : styles.notSelected
            }
          >
            Locations
          </button>
        </Link>
        <Link to="/workers">
          <button
            onClick={() => setSelectedPage("/workers")}
            className={
              selectedPage === "/workers" ? styles.selected : styles.notSelected
            }
          >
            Workers
          </button>
        </Link>

        <Link to="/workunits">
          <button
            onClick={() => setSelectedPage("/workunits")}
            className={
              selectedPage === "/workunits"
                ? styles.selected
                : styles.notSelected
            }
          >
            Work Units
          </button>
        </Link>
        <Link to="/create">
          <button
            onClick={() => setSelectedPage("/create")}
            className={
              selectedPage === "/create" ? styles.selected : styles.notSelected
            }
          >
            {" "}
            Create
          </button>
        </Link>

        <Link to="/addons">
          <button
            onClick={() => setSelectedPage("/addons")}
            className={
              selectedPage === "/addons" ? styles.selected : styles.notSelected
            }
          >
            {" "}
            Addons
          </button>
        </Link>
        <Link to="/Roles">
          <button
            onClick={() => setSelectedPage("/roles")}
            className={
              selectedPage === "/roles" ? styles.selected : styles.notSelected
            }
          >
            {" "}
            Roles
          </button>
        </Link>
      </div>
      <p onClick={() => signAccountOut().then(()=> {
        navigate("/")
      })} className={styles.logout}>
        logout
      </p>
    </header>
  );
};

export default Header;
