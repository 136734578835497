import { Dispatch, FC, SetStateAction, useState } from "react";

import styles from "./Create.module.scss";
import WorkerForm from "./forms/WorkerForm";
import { WorkUnitType } from "../models/WorkUnit";
import { LocationType } from "../models/Location";
import { WorkerType } from "../models/Worker";
import LocationForm from "./forms/LocationForm";
import WorkUnitForm from "./forms/WorkUnitForm";
import { AddonType } from "../models/Addon";
import { RoleType } from "../models/Roles";
import Success from "./Success";
import Loading from "./Loading";
import AddonForm from "./forms/AddonForm";
import RoleForm from "./forms/RoleForm";

export type Props = {
  setLocations: Dispatch<SetStateAction<LocationType[]>>;
  setWorkUnits: Dispatch<SetStateAction<WorkUnitType[]>>;
  setWorkers: Dispatch<SetStateAction<WorkerType[]>>;
  setAddons: Dispatch<SetStateAction<AddonType[]>>;
  setRoles: Dispatch<SetStateAction<RoleType[]>>;
  workers: WorkerType[];
  locations: LocationType[];
  addons: AddonType[];
  roles: RoleType[];
  user: {password: string, email: string}
};
const Create: FC<Props> = ({
  setLocations,
  setWorkUnits,
  setWorkers,
  setAddons,
  setRoles,
  workers,
  locations,
  addons,
  roles,
  user
}) => {
  type FormType =
    | "worker"
    | "workunit"
    | "location"
    | "success"
    | "loading"
    | "select"
    | "addon"
    | "role";

  const [form, setForm] = useState<FormType>("select");
  return (
    <>
      <header className={styles.titleAndButtons}>
        {form === "worker" && (
          <h1 className={styles.marginRight}>Create New Worker</h1>
        )}
        {form === "location" && (
          <h1 className={styles.marginRight}>Create New Location</h1>
        )}
        {form === "workunit" && (
          <h1 className={styles.marginRight}>Create New Work Unit</h1>
        )}
        {form === "addon" && (
          <h1 className={styles.marginRight}>Create New Addon</h1>
        )}
        {form === "role" && (
          <h1 className={styles.marginRight}>Create New Role</h1>
        )}
        <button
          onClick={() => setForm("worker")}
          className={form === "worker" ? styles.selected : styles.notSelected}
        >
          Worker
        </button>
        <button
          onClick={() => setForm("location")}
          className={form === "location" ? styles.selected : styles.notSelected}
        >
          Location
        </button>
        <button
          onClick={() => setForm("workunit")}
          className={form === "workunit" ? styles.selected : styles.notSelected}
        >
          Work Unit
        </button>
        <button
          onClick={() => setForm("addon")}
          className={form === "addon" ? styles.selected : styles.notSelected}
        >
          Addon
        </button>
        <button
          onClick={() => setForm("role")}
          className={form === "role" ? styles.selected : styles.notSelected}
        >
          Role
        </button>
      </header>
      <div className={styles.formContainer}>
        {form === "worker" && (
          <WorkerForm
          userX={user}
            setWorkers={setWorkers}
            setSuccess={() => setForm("success")}
            setLoading={() => setForm("loading")}
            roles={roles}
          />
        )}
        {form === "location" && (
          <LocationForm
            setLocations={setLocations}
            setSuccess={() => setForm("success")}
            setLoading={() => setForm("loading")}
          />
        )}
        {form === "workunit" && (
          <WorkUnitForm
            setSuccess={() => setForm("success")}
            setLoading={() => setForm("loading")}
            setWorkUnits={setWorkUnits}
            addons={addons}
            locations={locations}
            roles={roles}
            workers={workers}
          />
        )}
        {form === "addon" && (
          <AddonForm
            setAddons={setAddons}
            setSuccess={() => setForm("success")}
            setLoading={() => setForm("loading")}
          />
        )}
        {form === "role" && (
          <RoleForm
            setRoles={setRoles}
            setSuccess={() => setForm("success")}
            setLoading={() => setForm("loading")}
          />
        )}
        {form === "success" && <Success />}
        {form === "loading" && <Loading />}
        {form === "select" && (
          <div>
            {" "}
            <h1>Select an option</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default Create;
