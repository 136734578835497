import * as ReactDOMClient from 'react-dom/client';
import "./utils/firebase";

import { BrowserRouter } from "react-router-dom";

import "./index.css";

import AuthController from "./AuthController";



const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);
root.render(<BrowserRouter>{<AuthController />}</BrowserRouter>);
