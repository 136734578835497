import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import type { CascaderProps } from "antd";
import styles from "./EditWorker.module.scss";
import { format } from "date-fns";

import {
  Popconfirm,
  Divider,
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  message,
  DatePicker,
  Form,
  notification,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { WorkerType } from "../../models/Worker";
import { RoleType } from "../../models/Roles";
import {
  updateAndCalcTotalOfWorkUnit,
  updateWorkUnit,
  updateWorker,
} from "../../utils/firebase";
import Loading from "../Loading";
import { NotificationInstance } from "antd/es/notification/interface";
import { WorkUnitType } from "../../models/WorkUnit";
import { LocationType } from "../../models/Location";
import { formatDateToUS, getTimeInAMPM } from "../../utils/time";
import { AddonType, addonToJson } from "../../models/Addon";
import { v4 as uuidv4 } from "uuid";
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

type Props = {
  workers: WorkerType[];
  roles: RoleType[];
  locations: LocationType[];
  workUnit: WorkUnitType;
  addons: AddonType[];
  finishEdit: () => void;
  setWorkUnits: Dispatch<SetStateAction<WorkUnitType[]>>;
};

const EditWorkUnit: React.FC<Props> = ({
  workUnit,
  workers,
  addons,
  locations,
  finishEdit,
  roles,
  setWorkUnits,
}) => {
  const [form] = Form.useForm();
  const [workUnitX, setworkUnit] = useState(workUnit);
  const [isLoading, setIsLoading] = useState(false);

  const [start, setStart] = useState<number>();
  const [end, setEnd] = useState<number>();

  const [customAddons, setCustomAddons] = useState<AddonType[]>([]);

  const addCustomAddon = () => {
    const uid = uuidv4();
    const customAddon: AddonType = {
      name: "",
      basicPay: 0,
      isCustom: true,
      id: uid,
    };
    setCustomAddons((a) => {
      return [...a, customAddon];
    });
  };

  const removeCustom = (id: string) => {
    setCustomAddons((ca) => {
      return [...ca.filter((e) => e.id !== id)];
    });
  };

  const onFinish = (values: any) => {};

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "This is a success message",
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <header className={styles.editHeader}>
        <h1>Edit WorkUnit from {workUnitX.worker.name}</h1>
        <button onClick={() => finishEdit()}> Back</button>
      </header>
      <Form
        className={styles.form}
        {...formItemLayout}
        form={form}
        size="large"
        name="EditWorkUnitForm"
        onFinish={onFinish}
        initialValues={
          {
            // residence: ["zhejiang", "hangzhou", "xihu"],
            // prefix: "86",
          }
        }
        style={{ maxWidth: 600 }}
        scrollToFirstError
      >
        {/* Daterange */}
        <Form.Item
          initialValue={
            formatDateToUS(workUnitX.start) +
            " - " +
            getTimeInAMPM(workUnitX.start)
          }
          className={styles.input}
          name="currentStart"
          label="Current Start"
        >
          <Input disabled={true} />
        </Form.Item>

        {workUnitX.end && (
          <Form.Item
            initialValue={
              formatDateToUS(workUnitX.end!) +
              " - " +
              getTimeInAMPM(workUnitX.end!)
            }
            className={styles.input}
            name="currentEnd"
            label="Current End"
          >
            <Input disabled={true} />
          </Form.Item>
        )}

        <Form.Item
          label="DatePicker"
          name="daterange"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <RangePicker
            showTime
            format={"YYYY-MM-DD HH:mm"}
            onChange={(obj: any) => {
              if (obj && obj[0]["$d"] instanceof Date) {
                setStart(obj[0]["$d"].getTime());
                setEnd(obj[1]["$d"].getTime());
              }
            }}
          />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (!start || !end) return;
              setIsLoading(true);

              updateWorkUnit({ start: start }, workUnit.id).then(() => {
                updateWorkUnit({ end: end }, workUnit.id).then(() => {
                  workUnitX.start = start!;
                  workUnitX.end = end!;
                  setWorkUnits((w) => {
                    return w.map((wo) =>
                      wo.id === workUnitX.id ? workUnitX : wo
                    );
                  });
                  form.setFieldValue(
                    "currentStart",
                    formatDateToUS(workUnitX.start!) +
                      " - " +
                      getTimeInAMPM(workUnitX.start!)
                  );
                  form.setFieldValue(
                    "currentEnd",
                    formatDateToUS(workUnitX.end!) +
                      " - " +
                      getTimeInAMPM(workUnitX.end!)
                  );
                  setIsLoading(false);
                  message.success("Updated");
                });
              });
            }}
            type="primary"
          >
            Change Daterange
          </Button>
        </Form.Item>

        <Divider />
        {/* Roles */}
        <Form.Item
          initialValue={workUnitX.roles.map((r) => r.name).join(", ")}
          className={styles.input}
          name="currentRoles"
          label="Current Roles"
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          name="roles"
          label="New Roles"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <Checkbox.Group>
            <Row>
              {roles.map((r) => {
                return (
                  <Col span={8} key={r.id}>
                    <Checkbox
                      value={r}
                      style={{
                        lineHeight: "32px",
                      }}
                    >
                      {r.name}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (!form.getFieldValue("roles")) return;
              setIsLoading(true);

              workUnitX.roles = form.getFieldValue("roles");
              updateWorkUnit(
                {
                  roles: form.getFieldValue("roles").map((r: RoleType) => {
                    return { name: r.name, basicPay: r.basicPay };
                  }), //Edit Roles
                },
                workUnit.id
              ).then(async () => {
                await updateAndCalcTotalOfWorkUnit(workUnitX);
                setWorkUnits((w) => {
                  return w.map((wo) =>
                    wo.id === workUnitX.id ? workUnitX : wo
                  );
                });
                form.setFieldValue(
                  "currentRoles",
                  form
                    .getFieldValue("roles")
                    .map((r: RoleType) => r.name)
                    .join(", ")
                );

                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Roles
          </Button>
        </Form.Item>

        <Divider />
        {/* Worker */}

        <Form.Item
          initialValue={workUnitX.worker.name}
          className={styles.input}
          name="currentWorker"
          label="Current Worker"
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          label="Worker"
          name="workerId"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <Select>
            {workers.map((w) => {
              return (
                <Select.Option key={w.id} value={w.id}>
                  {w.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (!form.getFieldValue("workerId")) return;
              setIsLoading(true);

              workUnitX.worker = workers.find(
                (w) => w.id === form.getFieldValue("workerId")
              )!;
              updateWorkUnit(
                {
                  workerId: workUnitX.worker.id,
                },
                workUnitX.id
              ).then(() => {
                setWorkUnits((w) => {
                  return w.map((wo) =>
                    wo.id === workUnitX.id ? workUnitX : wo
                  );
                });
                form.setFieldValue("currentWorker", workUnitX.worker.name);

                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Woorker
          </Button>
        </Form.Item>

        <Divider />
        {/* Location */}

        <Form.Item
          initialValue={workUnitX.location.name}
          className={styles.input}
          name="currentLocation"
          label="Current Location"
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          label="Location"
          name="locationId"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <Select>
            {locations.map((l) => {
              return (
                <Select.Option key={l.id} value={l.id}>
                  {l.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (!form.getFieldValue("locationId")) return;
              setIsLoading(true);

              workUnitX.location = locations.find(
                (w) => w.id === form.getFieldValue("locationId")
              )!;
              updateWorkUnit(
                {
                  locationId: workUnitX.location.id,
                },
                workUnitX.id
              ).then(() => {
                setWorkUnits((w) => {
                  return w.map((wo) =>
                    wo.id === workUnitX.id ? workUnitX : wo
                  );
                });
                form.setFieldValue("currentLocation", workUnitX.location.name);

                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Location
          </Button>
        </Form.Item>

        <Divider />
        {/* Addons */}
        <Form.Item
          initialValue={workUnitX.addons.map((r) => r.name).join(", ")}
          className={styles.input}
          name="currentAddons"
          label="Current Addons"
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item name="addonIds" label="Addons">
          <Checkbox.Group>
            <Row>
              {addons.map((a) => {
                return (
                  <Col key={a.id} span={8}>
                    <Checkbox
                      value={a.id}
                      style={{
                        lineHeight: "32px",
                      }}
                    >
                      {a.name}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>

{/* Custom Addons */}


      {customAddons.map((ca) => {
        return (
          <Form.Item
            key={ca.id}
            label="Custom Addon"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name={ca.id}
              rules={[
                {
                  required: true,
                  message: "required",
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name={"amount" + ca.id}
              rules={[
                {
                  required: true,
                  message: "required",
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input type="number" placeholder="Amount" />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button onClick={() => removeCustom(ca.id)} type="dashed">
                Remove
              </Button>
            </Form.Item>
          </Form.Item>
        );
      })}
      <Form.Item {...tailFormItemLayout}>
        <Button onClick={() => addCustomAddon()} type="primary">
          Add Custom Addon
        </Button>
      </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              customAddons.forEach((ca) => {
                ca.basicPay = Number(form.getFieldValue("amount" + ca.id));
                ca.name = form.getFieldValue(ca.id);
              });

              if (!form.getFieldValue("addonIds")) return;
              setIsLoading(true);
              workUnitX.addons = addons.filter((a) =>
                form.getFieldValue("addonIds").includes(a.id)
              );
              workUnitX.addons = [...workUnitX.addons, ...customAddons]
              updateWorkUnit(
                {
                  addons: workUnitX.addons.map((a) => addonToJson(a)),
                },
                workUnit.id
              ).then(async () => {
                await updateAndCalcTotalOfWorkUnit(workUnitX);
                setWorkUnits((w) => {
                  return w.map((wo) =>
                    wo.id === workUnitX.id ? workUnitX : wo
                  );
                });
                form.setFieldValue(
                  "currentAddons",
                  workUnitX.addons.map((r: AddonType) => r.name).join(", ")
                );

                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Addons
          </Button>
        </Form.Item>

        <Divider />
        {/* Note */}
        <Form.Item
          name="currentNote"
          label="Current Note"
          initialValue={workUnitX.note}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item name="note" label="New Note">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            onClick={() => {
              if (form.getFieldValue("note") === undefined) {
                form.setFieldValue("note", "");
              }

              setIsLoading(true);

              workUnitX.note = form.getFieldValue("note");
              updateWorkUnit(
                {
                  note: form.getFieldValue("note"),
                },
                workUnit.id
              ).then(() => {
                setWorkUnits((w) => {
                  return w.map((wo) =>
                    wo.id === workUnitX.id ? workUnitX : wo
                  );
                });
                form.setFieldValue("currentNote", form.getFieldValue("note"));

                setIsLoading(false);
                message.success("Updated");
              });
            }}
            type="primary"
          >
            Change Note
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditWorkUnit;
