import { Dispatch, FC, SetStateAction, useState } from "react";
import type { CascaderProps } from "antd";

import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  TimePicker,
} from "antd";
import { addWorkUnit, addWorker } from "../utils/firebase";
import { workerToJson } from "../models/Worker";
import { WorkerType } from "../models/Worker";
import { WorkUnitType, WorkUnitUtils } from "../models/WorkUnit";
import { RoleType } from "../models/Roles";
import { LocationType } from "../models/Location";
import { AddonType } from "../models/Addon";
import Loading from "./Loading";
import styles from "./WorkerApp.module.scss";
import { v4 as uuidv4 } from "uuid";
import { signAccountOut } from "../utils/auth";
import { formatDateToUS, getTimeInAMPM } from "../utils/time";
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

type Props = {
  roles: RoleType[];
  workers: WorkerType[];
  locations: LocationType[];
  addons: AddonType[];
  loggedUser: WorkerType
  workUnits: WorkUnitType[]
  setWorkUnits: Dispatch<SetStateAction<WorkUnitType[]>>;
  // continue here, info when checked in + hide check in button & color selected button (work/history)  & test everything
};

const WorkerApp: React.FC<Props> = ({
  roles,
  workers,
  locations,
  addons,
  loggedUser,
  workUnits,
  setWorkUnits
}) => {
  const [start, setStart] = useState<number>();
  const [end, setEnd] = useState<number>();
  const [endDate, setEndDate] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [customAddons, setCustomAddons] = useState<AddonType[]>([]);


  const [isHistory, setIsHistory] = useState<boolean>(false)
  const [isCheckingIn, setIsCheckingIn] = useState<boolean>(false)

  const [form] = Form.useForm();
    const wUtils = new WorkUnitUtils() 
  
  const [currentWorkUnit, setCurrentWorkUnit] = useState<WorkUnitType | undefined>(workUnits.find(wu => !wUtils.hasFinished(wu) && wUtils.hasStarted(wu)))

  
  const addCustomAddon = () => {
    const uid = uuidv4();
    const customAddon: AddonType = {
      name: "",
      basicPay: 0,
      isCustom: true,
      id: uid,
    };
    setCustomAddons((a) => {
      return [...a, customAddon];
    });
  };

  const removeCustom = (id: string) => {
    setCustomAddons((ca) => {
      return [...ca.filter((e) => e.id !== id)];
    });
  };

  const addTotal = (unit: WorkUnitType) => {
    unit.total = 0;
    unit.roles.forEach((r: RoleType) => {
      unit.total += r.basicPay;
    });
    unit.addons.forEach((a: AddonType) => {
      unit.total += a.basicPay;
    });
  };

  const onFinish = async (values: any) => {
    customAddons.forEach((ca) => {
      ca.basicPay = Number(values["amount" + ca.id]);
      ca.name = values[ca.id];
    });
    
    const unit = values as WorkUnitType;

    unit.end = endDate + endTime;
    unit.start = start ? start : 0;
    unit.note = unit.note ? unit.note : "";
    unit.location = locations.find((l) => l.id === values.locationId)!;
    unit.worker = workers.find((l) => l.id === values.workerId)!;
    unit.addons = [];
    if (values.addonIds) {
      unit.addons = addons.filter((a) => values.addonIds.includes(a.id));
    }
    unit.addons.push(...customAddons);
    unit.roles = [];
    if (values.roleIds) {
      unit.roles = roles.filter((r) => values.roleIds.includes(r.id));
    }

    addTotal(unit);
    setIsLoading(true);
    setIsCheckingIn(false)
    const id = await addWorkUnit({...unit, worker: loggedUser});
    unit.id = id ? id : "";

    setIsLoading(false);
    if (id) {
      setIsSuccess(true);
      setWorkUnits(wus => {
        return [...wus, {...unit, worker: loggedUser}]
      })
      setCurrentWorkUnit([...workUnits, {...unit, worker: loggedUser}].find(wu => wUtils.hasStarted(wu) && !wUtils.hasFinished(wu)))
    }
  };

  if (isLoading || !roles || !addons || !workers || !locations) {
    return <Loading />;
  }




  if (isSuccess) {
    form.resetFields();
    customAddons.forEach((c) => {
      setCustomAddons([]);
      setStart(undefined);
      setEnd(undefined);
    });
    return (
      <div className={styles.successContainer}>
        <h1>Successfully created</h1>
        <button onClick={() => setIsSuccess(false)}>Back to Check in</button>
      </div>
    );
  }



  return (
    <>
     
      <header className={styles.header} >
        <button className={isHistory ? styles.notSelected : styles.selected} onClick={() => setIsHistory(false)} >Work</button>
        <button className={!isHistory ? styles.notSelected : styles.selected}  onClick={() => setIsHistory(true)} >History</button>
        <button onClick={() => signAccountOut()} className={styles.logout}>
        logout
      </button>
      </header>
      <h3 className={styles.name} >{`${loggedUser.name} | ${loggedUser.nick}`}</h3>

      {/* ############################## HISTORY ########################### */}
      {isHistory && 
      <div>
        {workUnits.reverse().map((wo, index) => {
          if(!wUtils.hasFinished(wo)) return;
            if(wo.end) {

                return <div className={styles.card} key={wo.id} >
           
                    <h1 className={styles.index} >{workUnits.length - index}</h1>
                 
                    <h1 className={styles.location} >{wo.location.name}</h1>
                    <Divider className={styles.divider} /> 
                <p>{`${formatDateToUS(wo.start)} ${getTimeInAMPM(wo.start)} to ${formatDateToUS(wo.end!)} ${getTimeInAMPM(wo.end!)} ` }</p>

                <Divider className={styles.divider}  /> 
                <p className={styles.strong}>Roles:</p>
                <p> {wo.roles.map(r=> r.name + ": $" + r.basicPay).join(", ")}</p>
                <Divider className={styles.divider} /> 
                <p  className={styles.strong}>Addons:</p>
                <p> {wo.addons.map(r=> r.name + ": $" + r.basicPay).join(", ")}</p>
                <Divider className={styles.divider} /> 
                {wo.note && <p><span className={styles.strong}>Note:</span> {wo.note}</p>}
                <Divider className={styles.divider} /> 
                <p className={styles.total} >Total: <span className={styles.strong} >
                  ${wo.total}
                  </span> 
                  </p>
            </div>
            }
        })}
      </div>
      
      }
      {!isHistory &&
        <> 
        {isCheckingIn && 
         <div className={styles.container}>
     
         <Form
         className={styles.form}
         {...formItemLayout}
         form={form}
         size="large"
         name="workerForm"
         onFinish={onFinish}
         style={{ maxWidth: 800 }}
         scrollToFirstError
         >

        <h1 className={styles.title} >Check in</h1>
        <p className={styles.cancel} onClick={() => setIsCheckingIn(false)} >cancel</p>
         
         {/* Roles */}
         <Divider />
         <Form.Item
             name="roleIds"
             label="Roles"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
               <Checkbox.Group >
               <Row>
               {roles.map((r) => {
                   return (
                       <Col span={10} key={r.id} >
                       <Checkbox
                       value={r.id}
                       style={{
                           lineHeight: "32px",
                       }}
                       >
                         {r.name}
                       </Checkbox>
                     </Col>
                   );
               })}
               </Row>
             </Checkbox.Group>
           </Form.Item>
   
           <Divider />
{/* 
           <Form.Item
             label="Start Date"
             name="startdate"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
               
             <DatePicker
             
             size="large"
               format={"YYYY-MM-DD"}
               
               onChange={(obj: any) => {
                   console.log(obj)
                   console.log(obj["$d"].getTime())
               }}
               />
           </Form.Item> */}

           <Form.Item
             label="Start Time"
             name="starttime"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
               
             <TimePicker
             
             size="large"
               format={"HH-mm"}
               
               onChange={(obj: any) => {
                   console.log(obj)
                   console.log(obj["$d"].getTime())
                   setStart(obj["$d"].getTime())
               }}
               />
           </Form.Item>


        

          <Form.Item
             label="End Date"
             name="enddate"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
               
             <DatePicker
             
             size="large"
               format={"YYYY-MM-DD"}
               
               onChange={(obj: any) => {
                   console.log(obj)
                   console.log(obj["$d"].getTime())
                   setEndDate(obj["$d"].getTime())
               }}
               />
           </Form.Item>


           <Form.Item
             label="End Time"
             name="endtime"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
               
             <TimePicker
             
             size="large"  
               format={"HH-mm"}
               
               onChange={(obj: any) => {
                   console.log(obj)
                   console.log(obj["$m"])
                   console.log(obj["$H"])
                   setEndTime(obj["$m"] * 1000 *60 + obj["$H"] * 1000 * 60 * 60)
               }}
               />
           </Form.Item> 
       
       {/*     
           <Form.Item
             label="Daterange"
             name="daterange"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
               
             <RangePicker
              showTime
             
              style={{width: "100%"}}
             size="middle"  
               format={"YYYY-MM-DD HH-mm"}
               onChange={(obj: any) => {
                   if ( obj !== null && obj !== undefined && obj[0]["$d"] instanceof Date) {
                       setStart(obj[0]["$d"].getTime());
                       setEnd(obj[1]["$d"].getTime());
                   }
               }}
               />
           </Form.Item> */}
   {/* 
           <Divider />
           <Form.Item
             label="Name"
             name="workerId"
             rules={[
                 {
                     required: true,
                     message: "Please add the information",
                   },
               ]}
               >
             <Select>
               {workers.map((w) => {
                   return (
                       <Select.Option key={w.id} value={w.id}>
                     {w.name}
                   </Select.Option>
                 );
               })}
             </Select>
           </Form.Item> */}
   
          {/*  <Divider /> */}
   
           <Form.Item
           label="Location"
           name="locationId"
           rules={[
               {
                   required: true,
                   message: "Please add the information",
               },
           ]}
           >
             <Select>
               {locations.map((l) => {
                   return (
                       <Select.Option key={l.id} value={l.id}>
                     {l.name}
                   </Select.Option>
                 );
               })}
             </Select>
           </Form.Item>
   
           <Divider />
   
           <Form.Item name="addonIds" label="Addons">
             <Checkbox.Group>
               <Row>
                 {addons.map((a) => {
                     return (
                         <Col key={a.id} span={8}>
                       <Checkbox
                         value={a.id}
                         style={{
                             lineHeight: "32px",
                           }}
                           >
                         {a.name}
                       </Checkbox>
                     </Col>
                   );
               })}
               </Row>
             </Checkbox.Group>
           </Form.Item>
   
           <Divider />
           {customAddons.map((ca) => {
               return (
                   <Form.Item
                   key={ca.id}
                   label="Custom Addon"
                   style={{
                       marginBottom: 0,
                   }}
                   >
                 <Form.Item
                   name={ca.id}
                   rules={[
                       {
                           required: true,
                           message: "required",
                       },
                   ]}
                   style={{
                       display: "inline-block",
                       width: "calc(50% - 8px)",
                   }}
                   >
                   <Input placeholder="Name" />
                 </Form.Item>
                 <Form.Item
                   name={"amount" + ca.id}
                   rules={[
                       {
                           required: true,
                           message: "required",
                       },
                   ]}
                   style={{
                       display: "inline-block",
                       width: "calc(50% - 8px)",
                       margin: "0 8px",
                   }}
                   >
                   <Input type="number" placeholder="Amount" />
                 </Form.Item>
                 <Form.Item {...tailFormItemLayout}>
                   <Button onClick={() => removeCustom(ca.id)} type="dashed">
                     Remove
                   </Button>
                 </Form.Item>
               </Form.Item>
             );
           })}
           <Form.Item {...tailFormItemLayout}>
             <Button onClick={() => addCustomAddon()} type="primary">
               Add Custom Addon
             </Button>
           </Form.Item>
   
           <Divider />
           <Form.Item name="note" label="Note">
          
             <Input />
           </Form.Item>
   
   
           <Form.Item {...tailFormItemLayout}>
             <Button type="primary" htmlType="submit">
               Create
             </Button>
           </Form.Item>
         </Form>
         </div> 
        }
        {!isCheckingIn && 
<>
            {!currentWorkUnit &&  
                <div className={styles.checkinContainer} >

                <button className={styles.checkin} onClick={() => setIsCheckingIn(true)} >Check in</button>
                </div>
                
            }


      {/* ############################## CURRENT UNIT ########################### */}
      {currentWorkUnit &&
      <> 
      <h1 className={styles.signedInMessage} >currently signed in</h1>
      <div className={styles.card} key={currentWorkUnit.id} >
           
    
           <h1 className={styles.location} >{currentWorkUnit.location.name}</h1>
           <Divider className={styles.divider} /> 
       <p>{`${formatDateToUS(currentWorkUnit.start)} ${getTimeInAMPM(currentWorkUnit.start)} to ${formatDateToUS(currentWorkUnit.end!)} ${getTimeInAMPM(currentWorkUnit.end!)} ` }</p>

       <Divider className={styles.divider}  /> 
       <p className={styles.strong}>Roles:</p>
       <p> {currentWorkUnit.roles.map(r=> r.name + ": $" + r.basicPay).join(", ")}</p>
       <Divider className={styles.divider} /> 
       <p  className={styles.strong}>Addons:</p>
       <p> {currentWorkUnit.addons.map(r=> r.name + ": $" + r.basicPay).join(", ")}</p>
       <Divider className={styles.divider} /> 
       {currentWorkUnit.note && <p><span className={styles.strong}>Note:</span> {currentWorkUnit.note}</p>}
       <Divider className={styles.divider} /> 
       
       <p className={styles.total} >Total: <span className={styles.strong} >
         ${currentWorkUnit.total}
         </span> 
         </p>
   </div>
   </>
      }
      </>}

    </>
    }
    </>
);
};

export default WorkerApp;
