import { Dispatch, FC, SetStateAction, useState } from "react";
import type { CascaderProps } from "antd";

import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { addWorkUnit, addWorker } from "../utils/firebase";
import { workerToJson } from "../models/Worker";
import { WorkerType } from "../models/Worker";
import { WorkUnitType } from "../models/WorkUnit";
import { RoleType } from "../models/Roles";
import { LocationType } from "../models/Location";
import { AddonType } from "../models/Addon";
import Loading from "./Loading";
import styles from "./HotelCreateUnit.module.scss";
import { v4 as uuidv4 } from "uuid";
import { signAccountOut } from "../utils/auth";
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
type Props = {
  roles: RoleType[];
  workers: WorkerType[];
  locations: LocationType[];
  addons: AddonType[];
};

const HotelCreateUnit: React.FC<Props> = ({
  roles,
  workers,
  locations,
  addons,
}) => {
  const [start, setStart] = useState<number>();
  const [end, setEnd] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [customAddons, setCustomAddons] = useState<AddonType[]>([]);

  const [form] = Form.useForm();

  const addCustomAddon = () => {
    const uid = uuidv4();
    const customAddon: AddonType = {
      name: "",
      basicPay: 0,
      isCustom: true,
      id: uid,
    };
    setCustomAddons((a) => {
      return [...a, customAddon];
    });
  };

  const removeCustom = (id: string) => {
    setCustomAddons((ca) => {
      return [...ca.filter((e) => e.id !== id)];
    });
  };

  const addTotal = (unit: WorkUnitType) => {
    unit.total = 0;
    unit.roles.forEach((r: RoleType) => {
      unit.total += r.basicPay;
    });
    unit.addons.forEach((a: AddonType) => {
      unit.total += a.basicPay;
    });
  };

  const onFinish = async (values: any) => {
    customAddons.forEach((ca) => {
      ca.basicPay = Number(values["amount" + ca.id]);
      ca.name = values[ca.id];
    });
    const unit = values as WorkUnitType;
    unit.end = end;
    unit.start = start ? start : 0;
    unit.note = unit.note ? unit.note : "";
    unit.location = locations.find((l) => l.id === values.locationId)!;
    unit.worker = workers.find((l) => l.id === values.workerId)!;
    unit.addons = [];
    if (values.addonIds) {
      unit.addons = addons.filter((a) => values.addonIds.includes(a.id));
    }
    unit.addons.push(...customAddons);
    unit.roles = [];
    if (values.roleIds) {
      unit.roles = roles.filter((r) => values.roleIds.includes(r.id));
    }

    addTotal(unit);
    setIsLoading(true);
    const id = await addWorkUnit(unit);
    unit.id = id ? id : "";

    setIsLoading(false);
    if (id) {
      setIsSuccess(true);
    }
  };

  if (isLoading || !roles || !addons || !workers || !locations) {
    return <Loading />;
  }

  if (isSuccess) {
    form.resetFields();
    customAddons.forEach((c) => {
      setCustomAddons([]);
      setStart(undefined);
      setEnd(undefined);
    });
    return (
      <div className={styles.successContainer}>
        <h1>Successfully created</h1>
        <button onClick={() => setIsSuccess(false)}>Back to Check in</button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <button onClick={() => signAccountOut()} className={styles.logout}>
        logout
      </button>
      <Form
        className={styles.form}
        {...formItemLayout}
        form={form}
        size="large"
        name="workerForm"
        onFinish={onFinish}
        style={{ maxWidth: 800 }}
        scrollToFirstError
      >
        <h1 className={styles.title}>Check in</h1>

        {/* Roles */}
        <Divider />
        <Form.Item
          name="roleIds"
          label="Roles"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <Checkbox.Group>
            <Row>
              {roles.map((r) => {
                return (
                  <Col span={8} key={r.id}>
                    <Checkbox
                      value={r.id}
                      style={{
                        lineHeight: "32px",
                      }}
                    >
                      {r.name}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Divider />
        <Form.Item
          label="DatePicker"
          name="daterange"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <RangePicker
            showTime
            format={"YYYY-MM-DD HH:mm"}
            onChange={(obj: any) => {
              if (obj[0]["$d"] instanceof Date) {
                setStart(obj[0]["$d"].getTime());
                setEnd(obj[1]["$d"].getTime());
              }
            }}
          />
        </Form.Item>

        <Divider />
        <Form.Item
          label="Name"
          name="workerId"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <Select>
            {workers.map((w) => {
              return (
                <Select.Option key={w.id} value={w.id}>
                  {w.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          label="Location"
          name="locationId"
          rules={[
            {
              required: true,
              message: "Please add the information",
            },
          ]}
        >
          <Select>
            {locations.map((l) => {
              return (
                <Select.Option key={l.id} value={l.id}>
                  {l.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item name="addonIds" label="Addons">
          <Checkbox.Group>
            <Row>
              {addons.map((a) => {
                return (
                  <Col key={a.id} span={8}>
                    <Checkbox
                      value={a.id}
                      style={{
                        lineHeight: "32px",
                      }}
                    >
                      {a.name}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Divider />
        {customAddons.map((ca) => {
          return (
            <Form.Item
              key={ca.id}
              label="Custom Addon"
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                name={ca.id}
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name={"amount" + ca.id}
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input type="number" placeholder="Amount" />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button onClick={() => removeCustom(ca.id)} type="dashed">
                  Remove
                </Button>
              </Form.Item>
            </Form.Item>
          );
        })}
        <Form.Item {...tailFormItemLayout}>
          <Button onClick={() => addCustomAddon()} type="primary">
            Add Custom Addon
          </Button>
        </Form.Item>

        <Divider />
        <Form.Item name="note" label="Note">
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default HotelCreateUnit;
