export type AddonType = {
  id: string;
  name: string;
  basicPay: number;
  isCustom: boolean;
};

export const addonToJson = (addon: AddonType) => {
  return {
    name: addon.name,
    basicPay: addon.basicPay,
    isCustom: addon.isCustom,
  };
};
