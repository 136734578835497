export type RoleType = {
  id: string;
  name: string;
  basicPay: number;
};

export const roleToJson = (role: RoleType) => {
  return {
    name: role.name,
    basicPay: role.basicPay,
  };
};
