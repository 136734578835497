import { FC } from "react";

type Props = {};

const Success: FC<Props> = () => {

  return (
    <div>
      <h1>Successfully created</h1>
    </div>
  );
};

export default Success;
